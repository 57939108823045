module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Demo Saddleback","short_name":"Demo Saddleback","start_url":"/","theme_color":"#fff","background_color":"#fff","display":"standalone","icon":"src/images/lobster512.png","include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"5c6ad25815ffb72e89b673b2d99f2da1"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://saddleback.lobstermarketing.com","noQueryString":true},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-tagmanager-timeout/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PHR5M4C","includeInDevelopment":false,"timeout":5000,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"page-change"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Home","exclude":["/dev-404-page/","/404/","/404.html","/offline-plugin-app-shell-fallback/"],"crumbLabelUpdates":[{"pathname":"/blog","crumbLabel":"Blog"},{"pathname":"/undefined","crumbLabel":"Pest Library"},{"pathname":"/where-we-service","crumbLabel":"Where We Service"}]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-70},
    },{
      plugin: require('../../gatsby-theme-saddleback/gatsby-browser.js'),
      options: {"plugins":[],"projectId":"7ba55415-5497-00fd-d586-cf3f137275fd","authorizationKey":"ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogIjA2YTNkOGJlOTQ0MTQ4YzdiZTBjMDQ0NTBhOGU0NGMwIiwNCiAgImlhdCI6ICIxNjExNzc5OTc5IiwNCiAgImV4cCI6ICIxOTU3Mzc5OTc5IiwNCiAgInByb2plY3RfaWQiOiAiN2JhNTU0MTU1NDk3MDBmZGQ1ODZjZjNmMTM3Mjc1ZmQiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.bKpCrW6icu-whxzhVTFrRRfrWiPiPlR5sSIdW8LkIl4","usePreviewUrl":"false","whereWeServicePathName":"where-we-service","whereWeServiceBreadcrumb":"Where We Service","wWSActive":"0","siteUrl":"https://saddleback.lobstermarketing.com","title":"Demo Saddleback","description":"FieldRoutes","domainVerification":"meta-tag","gtmId":"GTM-PHR5M4C","compassID":"42a82a20fd60882a6cf4067db94368738701fce0d5ce61ec6c4845b74d313fbc","recaptchaSiteKey":"6LeRfd4hAAAAAAiS7he-35qYesaW0iYORXe8LOwN","recaptchaSecretKey":"6LeRfd4hAAAAAGZ3uVhBk8AjZ6CI0_9i1WBWG8Qx","estLibraryPathName":"pest-library","pestLibraryBreadcrumb":"Pest Library","pestLibraryActive":"0"},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
